import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ApiResponseStatusGlobalDisplayState = {
  isLoading: boolean;
  isError: boolean;
  error?: string;
};

const initialState: ApiResponseStatusGlobalDisplayState = {
  isLoading: false,
  isError: false,
  error: undefined,
};

const apiResponseStatusGlobalDisplaySlice = createSlice({
  name: 'apiResponseStatus',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsError: (state, action: PayloadAction<boolean>) => {
      state.isError = action.payload;
      if (!action.payload) {
        state.error = undefined;
      }
    },
    setError: (state, action: PayloadAction<string | undefined>) => {
      state.error = action.payload;
    },
  },
});

export const { setIsLoading, setIsError, setError } = apiResponseStatusGlobalDisplaySlice.actions;
export const apiResponseStatusGlobalDisplayReducer = apiResponseStatusGlobalDisplaySlice.reducer;
